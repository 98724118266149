import React from 'react'

const About = () => (
  <div
    id='about'
    className='container sm-md'
    style={{
      paddingTop: 30,
      marginTop:  30,
      marginBottom: 30,
      paddingBottom: 30
    }}
    >
    <h4
      style={{
        fontSize: 30,
        fontWeight: 600,
        margin: 0
      }}>
      About Us
    </h4>
    <p
      style={{
        fontSize: 14,
        color: '#666',
        lineHeight: 1.6,
        wordSpacing: 1,
        margin: 0,
      }}
    > 
      <br />
      ComGu is an Israeli boutique solution house, founded in 2013, focused on <b>Communication &amp; Connectivity</b>.<br />
      We offer comprehensive solutions and services primarily focusing on the Telecom, Transportation and Critical Communication market segments.
      Thanks to our in-depth knowledge we are positioned as the technology partner of a wide range for customers and as a trusted reseller of leading global product companies.
      We predominately serve telecom service providers, railways, utilities and large enterprises.
    </p>
    <h5
      style={{
        fontWeight: 200,
        marginBottom: 25,
        marginTop: 0,
        fontSize: 25
      }}
    >
      <br /> <br />
      Our expertise at your service - It's not just our slogan, it's our way of doing business
    </h5>
  </div>
)

export default About