import React from 'react';
import { AiTwotoneDatabase } from 'react-icons/ai';
import './App.css';
import About from './elements/about';
import Cards from './elements/cards';
import ContactForm from './elements/contactForm';
import Customers from './elements/customers';
import Footer from './elements/footer';
import Navbar from './elements/navbar';
import Parallax from './elements/parallax';
import Partners from './elements/Partners';
import People from './elements/people';
import ProductPortfolio from './elements/productPortfolio';
import Services from './elements/services';
import ServicesCards from './elements/servicesCards';

function App() {
  return (
    <div className='app'>
      <div style={{
        width: '100%',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 111,
        backgroundColor: '#fff',
        paddingTop: 12,
        paddingBottom: 12
      }}>
        <Navbar />
      </div>
      <div
        style={{
          marginTop: 76
        }}
      >
        <Parallax />
        <About />
        <Cards />
        <People  />
        <ProductPortfolio />
        <Services />
        <ServicesCards />
        {/* <Partners /> */}
        <Customers />
        <ContactForm />
        <Footer />
      </div>
    </div>
  );
}

export default App;
