import React from 'react'

const Parallax = () => {
  var TxtType = function(el, toRotate, period) {
    this.toRotate = toRotate;
    this.el = el;
    this.loopNum = 0;
    this.period = parseInt(period, 10) || 2000;
    this.txt = '';
    this.tick();
    this.isDeleting = false;
};

TxtType.prototype.tick = function() {
    var i = this.loopNum % this.toRotate.length;
    var fullTxt = this.toRotate[i];

    if (this.isDeleting) {
    this.txt = fullTxt.substring(0, this.txt.length - 1);
    } else {
    this.txt = fullTxt.substring(0, this.txt.length + 1);
    }

    this.el.innerHTML = '<span class="wrap">'+this.txt+'</span>';

    var that = this;
    var delta = 200 - Math.random() * 100;

    if (this.isDeleting) { delta /= 2; }

    if (!this.isDeleting && this.txt === fullTxt) {
    delta = this.period;
    this.isDeleting = true;
    } else if (this.isDeleting && this.txt === '') {
    this.isDeleting = false;
    this.loopNum++;
    delta = 500;
    }

    setTimeout(function() {
    that.tick();
    }, delta);
};

window.onload = function() {
    var elements = document.getElementsByClassName('typewrite');
    for (var i=0; i<elements.length; i++) {
        var toRotate = elements[i].getAttribute('data-type');
        var period = elements[i].getAttribute('data-period');

        if (toRotate) {
          new TxtType(elements[i], JSON.parse(toRotate), period);
        }
    }
    // INJECT CSS
    var css = document.createElement("style");
    css.innerHTML = ".typewrite > .wrap { border-right: 0.04em solid #aebcd9}";
    document.body.appendChild(css);
};

  return (
    <div
      id='home'
      className='parallax'
    >
      <div id='black' className='black'></div>
      <h1>
        <b>
          <span>
            <a
              href="" 
              className="typewrite" 
              data-period="1500"
              data-type='[ "Telecommunication", "Critical Communication", "Transportation" ]'>
              <span class="wrap"></span>
            </a>
          </span>
        </b>
        <div className='icon-el' style={{ width: '100%', height: '100%' }}>
        </div>
      </h1>
      {/* <h1>
        <span
          // style={{ width: '100%'}}
        >
          <b
            id='tele'
            >
            Telecommunication 
            <br />
            <FaSatelliteDish />
          </b>
  
          <b
            id='critical'
            style={{ display: 'none' }}
          >
            Critical Communication
            <br />
            <IoMdMegaphone />
          </b>
  
          <b
            className='transport'
            style={{ display: 'none' }}
          >
            Transportation
            <br />
            <GiBus />
          </b>
        </span>
      </h1> */}
      <div className='parallax-msg'>
        <p>
          Our Communication & Connectivity product portfolio 
          complemented with extensive services offering 
          enable our customers to benefit from most 
          advanced technology solutions
        </p>
      </div>
    </div>
  )
}

export default Parallax
