import React from 'react'
import { FaSatelliteDish } from 'react-icons/fa'
import { IoMdMegaphone } from 'react-icons/io'
import { GiBus } from 'react-icons/gi'

const ProductPortfolio = () => (
  <div
    id='products'
    className='sm-md'
    style={{
      width: '100%',
      backgroundColor: '#f7f7f7',
      paddingTop: 30,
      paddingBottom: 30
    }}
  >
    <div className='container'>
      <h1> Product Portfolio </h1>
      <p style={{ lineHeight: 1.6, marginBottom: 20 }}>
        Our product portfolio focuses on the below three market segments.
        In each of those segments we provide an extensive set of products and services enabling the introduction of new advanced communication and connectivity solutions.        
      </p>
    </div>
    <div
      className='container'
    >
      <div
        className='portfolio-card-wrapper'
        style={{
          display: "flex",
          flexDirection: 'row',
          justifyContent: 'space-around'
        }}
      >
        <div className='card sm-md-card portfolio-card'>
        <span> 
            <FaSatelliteDish />
          </span>
          <div className='portfolio-title'>
            <h6>
              Telecommunication
            </h6>
            <p className='portofio-tag'>
              Digitalizing the service provider environment
            </p>
            <br />
          </div>
          <ul>
            <li><FaSatelliteDish /> IMS/VoLTE/VoWiFi </li>
            <li><FaSatelliteDish /> 4G/5G Radio </li>
            <li><FaSatelliteDish /> Rich Communication Services (RCS) </li>
            <li><FaSatelliteDish /> ESIM / Entitlement Server </li>
            <li><FaSatelliteDish /> STP/DRA/DRE </li>
            <li><FaSatelliteDish />  Transmission systems </li>
            <li><FaSatelliteDish /> VAS </li>
            <li><FaSatelliteDish /> Provisioning/activation tools </li>
          </ul>
        </div>

        <div className='card portfolio-card'>
          <span>
            <IoMdMegaphone />
          </span>

          <div className='portfolio-title'>
            <h6>
              Critical Communication
            </h6>
            <p className='portofio-tag'>
              Comprehensive product portfolio targeted at 
              first responders, security entities and large 
              enterprises
            </p>
            <br />
          </div>
          <ul>
            <li><IoMdMegaphone /> Private networks (4G/5G) </li>
            <li><IoMdMegaphone /> PTT over LTE system</li>
            <li><IoMdMegaphone /> Video Surveillance </li>
            <li><IoMdMegaphone /> Vehicle communication device </li>
            <li><IoMdMegaphone /> Unified Cross Communication for first responders </li>
            <li><IoMdMegaphone />  Security staff location & positioning </li>
            <li><IoMdMegaphone /> Vehicle Fleet Management System </li>
          </ul>
        </div>

        <div className='card portfolio-card'>
          <div>
            <span>
              <GiBus />
            </span>
            <div className='portfolio-title'>
              <h6>
                Transportation
              </h6>
              <p className='portofio-tag'>
                The transport communication is speeding up
              </p>
              <br />
            </div>
          </div>
          <ul>
            <li><GiBus /> GSM-R train radio communication </li>
            <li><GiBus /> Public Announcement System</li>
            <li><GiBus /> LTE cab radio</li>
            <li><GiBus /> Vehicle communication device </li>
            <li><GiBus /> Train Radio Communication solution for rolling stock </li>
            <li><GiBus />  Passenger Information System (PIS) </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
)

export default ProductPortfolio