import React, { useRef, useState } from 'react';
import { BiMenu } from 'react-icons/bi';
import { AiOutlineClose } from 'react-icons/ai';
// import logo  from  '../statics/logo.png'
import diLogo  from  '../statics/dil.png'
import name  from  '../statics/name.png'
import '../App.css'

const Navbar  = ()  => {
  const ulEl = useRef(null)
  const [isMenueOpen, setMenu] = useState(false)

  const menuItems = [
    'Home',
    'About',
    'Products',
    'Services',
    'Customers',
    'Contact'
  ]

  const activateTargetElement = (event) => {
    event.persist()
    if (ulEl.current) {
      ulEl.current.childNodes.forEach(element => {
        element.firstChild.className = ''
      });
    }
    event.target.className = 'activeNavbar'
  }

  const getMenuList = () => {
    return (
      <>
        {menuItems.map((item, index) => {
          return (
            <li
              key={index}
              onClick={(e) => activateTargetElement(e)}
            > 
              <a href={`#${item.toLowerCase()}`}> 
                {item} 
              </a>
            </li>
          )
        })}
      </>
    )
  }

  return(
    <>
      <div style={{ 
      display: "flex",
      justifyContent: 'space-between', 
      maxWidth: 1140,
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
      // height: 80,
      alignItems: 'center',
      paddingLeft: 5,
      paddingRight: 5,
      boxSizing: 'border-box',
    }}>
      <div>
        <img src={diLogo} alt='logo' height={36} className='logo' />
        <img src={name} alt='logo' height={32} />
      </div>
      <div className='nav-items' style={{ alignItems: 'center', display: 'flex', justifyContent: 'flex-end', width: '70%' }}>
        <ul ref={ulEl} className='navUl' style={{ display: 'flex', justifyContent: 'space-between', width: '70%' }}>
          {getMenuList()}
        </ul>
      </div>
      <div className='menu-icon'  onClick={() => setMenu(!isMenueOpen)}>
        {isMenueOpen ? 
          <AiOutlineClose size={28} /> : <BiMenu size={24} />
        }
      </div>
    </div>
    <div
      className='mobile-nav'
      style={{
        display: isMenueOpen ? 'block' : 'none'
      }}
    >
      <ul>
        {getMenuList()}
      </ul>
    </div>
    </>
  )
}

export default Navbar;
