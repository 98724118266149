import React from 'react'
import person  from  '../statics/person.jpg'
import { AiFillLinkedin } from 'react-icons/ai'

const People = () => {
  return (
    <div style={{
      backgroundColor: '#fff'
    }}>
      <div
        className='container people'
      >
        {/* <h5 className='sm-md'> Our Executives </h5> */}
        <div
          className='executives'
          style={{
            display: 'flex',
            flexDirection: 'row',
            paddingBottom: 25,
            marginTop: 40
          }}
        >
          <div className='info'>
            <div className='avatar'>
              <img 
                src={person} 
                alt='logo'
                width={198}
                height={198}
              />
            </div>
            <div>
              <h6> Deepak Krishnamurthy </h6>
              <span style={{ color: '#555', fontSize: 14 }}> Founder </span>
            </div>
            <br />
            <p>
              Mr.Deepak Krishnamurthy has more than 12 years of experience in Development and 
              Executive Management positions in high-tech multinational companies. 
              Before founding Disha InfoSystem, Mr. Deepak Krishnamurthy was working with Aricent and Amdocs. 
              Mr.Deepak Krishnamurthy holds a Master of Computer Applications degree from 
              Visvesvaraya Technological University.
             <br />
            <a  href='https://linkedin.com'>
              <AiFillLinkedin />
            </a>
            </p>
          </div>
          {/* <div  className='info'>
            <div className='avatar'>
              <img src={person} alt='logo' width={258} height={258} />
            </div>
            <div>
              <h6> Eiton Idelson </h6>
              <span style={{ color: '#555', fontSize: 14 }}> Managing Partner </span>
            </div>
            <br />
            <p>
              Mr. Idelson has more than 25 years of experience in Sales, 
              Marketing, Business Development and Executive Management 
              positions in high-tech multinational companies. 
              Before founding ComGu, Mr. Idelson was Country Director & 
              Head of Sales for Nokia Networks Israel. Prior to that Mr. 
              Idelson served as AVP Product Sales of Comverse in APAC and 
              European Marketing Director for TTI Telecom. Mr. Idelson
              holds a Master degree in Finance (MBA) ,LL.B and Economics 
              degrees. <br />
            <a  href='https://linkedin.com'>
              <AiFillLinkedin />
            </a>
            </p>
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default People;