import React from 'react'
import { FaSatelliteDish } from 'react-icons/fa'
import { IoMdMegaphone } from 'react-icons/io'
import { GiBus } from 'react-icons/gi'

const Cards = () => (
  <div
    style={{
      width: '100%',
      backgroundColor: '#f7f7f7',
      paddingTop: 30,
      paddingBottom: 30
    }}
  >
    <div
      className='container'
    >
      <div
        className='cards-wrapper'
        style={{
          display: "flex",
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <div className='card sm-md-card'>
        <span> 
            <FaSatelliteDish />
          </span>
          <h6>
            Telecommunication Projects
          </h6>
          <p>
            We offer comprehensive solutions and services primarily focusing on the Telecom Domain(BSS). Becuase of our, in-depth knowledge we are positioned as the technology partner of a wide range of customers and as a trusted reseller of leading global product companies. We predominately serve telecom service providers and large enterprises.
          </p>
        </div>

        <div className='card sm-md-card'>
          <span>
            <IoMdMegaphone />
          </span>
          <h6>
            Testing Projects
          </h6>
          <p>
          We Provide quality engineering and wide range of software testing services. We have our own Testing Process , Technology Expertise, Domain knowledge with deep-skilled quality assurance professionals who have hands on with Functionality Testing, End to End testing and other major types of Testing activities.  
          </p>
        </div>

        <div className='card sm-md-card'>
          <span>
            <GiBus />
          </span>
          <h6>
            Automation Testing
          </h6>
          <p>
          Successive development cycles will require execution of same test suite repeatedly. Using a test automation tool. Once the test suite is automated, no human intervention is required. This improves ROI of Test Automation. The goal of Automation is to reduce the number of test cases to be run manually and not to eliminate Manual Testing altogether.        
          </p>
        </div>
      </div>
    </div>
  </div>
)

export default Cards