import React from 'react'
import { BsTools } from 'react-icons/bs'
import { FaKey, FaDatabase, FaBroadcastTower } from  'react-icons/fa'
import { GiSettingsKnobs } from 'react-icons/gi'
import { BsTerminal } from 'react-icons/bs'

const ServicesCards = () => (
  <div>
    <div className='container services-card sm-md'>
      <div class="flip-card">
        <div class="flip-card-inner">
          <div class="flip-card-front">
            <span>
            <FaBroadcastTower />
            </span>
            <p className="card-title">Telecommunication Projects (mainly in Billing software)</p>
          </div>
          <div class="flip-card-back">
            <div className="card-body">
              <p className="card-text">
              We offer comprehensive solutions and services primarily focusing on the Telecom Domain(BSS). Becuase of our, in-depth knowledge we are positioned as the technology partner of a wide range of customers and as a trusted reseller of leading global product companies. We predominately serve telecom service providers and large enterprises. At Disha InfoSystem we see technology as the way to drive our business forward.
	
  We are well equipped to provide solution for effective telecom billing software. We have a efficient development strategy to reduce the telecom expense & also covers all the major functionalities which includes Billing Software report which consist of Subscribers Call duration, time, message analysis, data expense. Billing System have the capability to Manage Customers, Rate List, Statistics data, Payment integration, Revenue Management, Invoice , Billing & Account Management and other features. These are for both Prepaid and Postpaid Customer. Our Best in Class Developers and Team can accommodate your requirements and provide efficient solution to you on time with Quality Check.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="flip-card">
        <div class="flip-card-inner">
          <div class="flip-card-front">
            <span>
              <FaKey />
            </span>
            <p class="card-title"> Testing Projects </p>
          </div>
          <div class="flip-card-back">
            <div className="card-body">
              <p className="card-text">
              "Improve your Product Quality & Process with Our Testing Service. “
We Provide quality engineering and wide range of software testing services. We have our own Testing Process , Technology Expertise, Domain knowledge with deep-skilled quality assurance professionals who have hands on with Functionality Testing, End to End testing and other major types of Testing activities.  
We take care of completely responsibility of all the testing ad quality assurance activity which starts from Test Plan till Production support. We have a dedicated team for Functionality testing ,  User centric Testing and End to end testing . we make sure all your business requirements are met with the help of traceability matrix. Our software Testing Experts are capable to test all type of desktop and mobile based application with focused approach to get better ROI and to improve time to market. 
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="flip-card">
        <div class="flip-card-inner">
          <div class="flip-card-front">
            <span>
              <GiSettingsKnobs />
            </span>
            <p class="card-title">Automation Testing mainly on Selenium</p>
          </div>
          <div class="flip-card-back">
            <div className="card-body">
              <p className="card-text">
              Successive development cycles will require execution of same test suite repeatedly. Using a test automation tool. Once the test suite is automated, no human intervention is required. This improves ROI of Test Automation. The goal of Automation is to reduce the number of test cases to be run manually and not to eliminate Manual Testing altogether.
Automation Testing has proved it can execute more test in less time, support continuous development, Speeding up cross browser, cross devise testing with that it increases Testing coverage,  increases speed , efficiency and quality.
Selenium is the Most widely used Test Automation tool which supports different Operating system in desktop & mobile . Selenium is used mainly for Functional testing and to manage regression Testing.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="flip-card">
        <div class="flip-card-inner">
          <div class="flip-card-front">
            <span>
              <BsTerminal />
            </span>
            <p class="card-title"> Support projects </p>
         </div>
          <div class="flip-card-back">
            <div className="card-body">
              <p class="card-text">
              Are you Looking for Best IT service Provided who have good hands on with Supporting stuffs ? Your Search ends here. We have a huge experience of handling support projects. If your product is new or Legacy , it doesn’t matter we have equipped with skilled team who are capable to take care your product and also your future change requests, Issue fixes or any new requirements. 
We will coordinate with Business Analyst, business unit, dev team & other stakeholder to to evaluate complex customer problem, identify root causes and derive resolutions. Work with support team to provide product support and assistance to customer. We maintain detailed documentation for all support activities including product development, enhancement, engineering and marketing activities.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="flip-card">
        <div class="flip-card-inner">
          <div class="flip-card-front">
            <span>
            <BsTools />
            </span>
          <p class="card-title">Monitoring projects</p>
          </div>
          <div class="flip-card-back">
            <div className="card-body">
            <p class="card-text">
            A proper and an effective monitoring system throughout the project execution can make the difference between a successful project and a failure. We Monitor and provide support to workflows and provide expert knowledge on all workflow designs. We Provide 24×7 operational support to all production practices on holidays and weekends which includes Coordinate with various teams and raise support ticket for all issues, analyze root cause and assist in efficient resolution of all production processes.
            </p>          
            </div>
          </div>
        </div>
      </div>

      {/* <div class="flip-card">
        <div class="flip-card-inner">
          <div class="flip-card-front">
            <span>
              <FaDatabase />
            </span>
            <p class="card-title">Oracle and Microsoft
            licensing optimization</p>
          </div>
          <div class="flip-card-back">
            <div className="card-body">
            <p class="card-text">
            Together with our reputable partner Miro Consulting
            we offer enterprises a wide range of services
            focusing on analyzing and negotiating enterprise
            software contracts - specifically Microsoft and
            Oracle licensing. Our offered service is based on
            the vast experience of Miro Consulting which is the
            largest independent Oracle and Microsoft Licensing
            optimization firm, serving the majority of fortune
            500 and 100 firms</p>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  </div>
)

export default ServicesCards