import React, { useState } from 'react'
import emailjs from 'emailjs-com';

const ContactForm = () => {

  const [isSubmitSuccess, setSubmitStatus] = useState(false)

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_7mzg5we', 'template_ffe5155', e.target, 'user_4nwHchNb2jTvbxXbe5QTV')
      .then((result) => {
        setSubmitStatus(true)
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
  }

  return (
   <div
    id='contact'
    style={{
      width: '100%',
      backgroundColor: 'darkgrey',
      padding: '25px 0'
    }}
   >
      <div className='container'>
        <form id="contact-form" className='form' onSubmit={sendEmail}>
          <div>
            <input type="text" name="user_name" placeholder='Full Name' />
            <input type="text" name="company_name" placeholder='Company Name' />
            <input type="email" name="user_email" placeholder='Email' />
          </div> <br />
          <input type="text" name="interest" className='p-o-interest' placeholder='Point Of Interest' />
          <br />
          <textarea name="message" placeholder='Message' rows={4} cols={100}></textarea>
          <br />
          <div className='input-button'>
            <input type="submit" value="Submit The Form" className='button' />
          </div>
        </form>
      </div>
   </div>
  )
}

export default ContactForm