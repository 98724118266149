import React from 'react'

const Services = () => (
  <div
    id='services'
    style={{
      width: '100%',
      backgroundColor: '#fff'
    }}>
    <div className='container sm-md'>
      <h1> Our Services </h1>
      <p>
        Our wide services portfolio helps our customers and partner 
        to meet their business goals and introduce new 
        technologies on time and on budget.
        To learn more about our offered services please click on 
        the below rectangles:
      </p>
    </div>
  </div>
)

export default Services