import React from 'react'
import customer from '../statics/costomer.jpg'

const  cutomerImages = [
customer,
]

const Customers = () => (
  <div id='customers' style={{ paddingBottom: 20 }}>
    <div className='container sm-md'>
      <h1> Our Customers </h1>
      <p style={{ lineHeight: 1.6, marginBottom: 20, color: '#666' }}>
        We are extremely proud of our growing with customers. Our focus is on 
        Telecom Operators, Utility Companies, Railways and large 
        Enterprises.
      </p>
    </div>
    <div className='container sm-md'>
      <div className='customer-images'>
        { cutomerImages.map((val, index) => {
          return (
            <div
              style={{ 
                minWidth: 160, 
                maxWidth: 160, 
                height: 65,
                padding: 15,
                paddingTop: 0
              }} 
              key={index}>
              <img src={val} width={180} height='auto' />
            </div>
          )
        })}
      </div>
    </div>
  </div>
)

export default Customers